<template>
  <b-card>
    <template #header>
      <div class="d-flex align-items-center">
        <span class="text-primary">Auto preferite </span>
        <b-breadcrumb class="ml-2">
          <b-breadcrumb-item>
            <feather-icon
              icon="HomeIcon"
              scale="1.25"
              shift-v="1.25"
            ></feather-icon>
          </b-breadcrumb-item>
          <b-breadcrumb-item active> Auto preferite</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </template>

    <div class="mt-2">
      <b-row>
        <b-col md="3">
          <b-button block variant="primary-light">
            Aggiungi <b>tutte</b> al carrello
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col
          v-for="car in cars.data"
          :key="car.id_auto"
          sm="12"
          md="6"
          lg="4"
          xl="3"
        >
          <b-card
            class="cursor-pointer"
            img
            @click="$router.push('/cars_on_sale/' + car.id_auto)"
          >
            <b-img
              class="mb-2 rounded rounded-b-0"
              fluid
              :src="'https://via.placeholder.com/350x250'"
            >
            </b-img>

            <div class="d-flex justify-content-between align-items-center">
              <p class="h3 m-0">€<b>20.000</b></p>

              <div
                class="d-flex justify-content-center align-items-center rounded-circle shadow shadow-md p-1"
                @click.stop="null"
              >
                <feather-icon
                  icon="StarIcon"
                  class="text-primary"
                  size="30"
                  @click="addToFavorites(car.id_auto)"
                />
              </div>
            </div>

            <div>
              <p class="mb-0 text-uppercase text-primary-light">
                <b>{{ car.marca }} - {{ car.modello }}</b>
              </p>
              <p>{{ car.versione }}</p>
            </div>

            <hr />

            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/engine.svg"
                  height="15px"
                />
                <span>{{ car.potenza }} CV</span>
              </div>

              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/fuel.svg"
                  height="20px"
                />
                <span>{{ car.alimentazione }}</span>
              </div>

              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/calendar.svg"
                  height="20px"
                />
                <span>{{ car.immatricolazione_anno }}</span>
              </div>
            </div>

            <hr />

            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/road.svg"
                  height="15px"
                />
                <span>{{ car.chilometraggio }} km</span>
              </div>

              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/cambio.svg"
                  height="20px"
                />
                <span>{{ car.tipo_cambio }}</span>
              </div>
            </div>

            <hr />

            <b-button block variant="primary">Aggiungi al carrello</b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
  },

  data: () => ({
    cars: [],

    //Helpers

    loading: false,
  }),

  created() {
    this.getFavoriteCars();
  },

  methods: {
    async getFavoriteCars() {
      this.loading = true;

      try {
        const response = await Requests.getFavoriteCars();
        this.cars = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>
